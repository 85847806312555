import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate  } from 'react-router-dom';


import '../App.css';

function Panel({title, content1, content2, style, img_path, is_left}) {
    return (
      <div style={{
        // flex:1,
        height:'60%',
        aspectRatio:1,
        // width:'40%',
        zIndex:2,
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        padding:0,
        paddingBottom:10,
        boxShadow: '0 10px 8px rgba(0, 0, 0, 0.1)',
        // marginTop:,
        ...style,
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }}>
          
          <div style={{
            backgroundColor: '#FF9F87',
            position:'absolute',
            width:'100%',
            height:'100%',
            zIndex:1,
          }}></div>
  
          <div style={{
            width:'90%', 
            marginLeft:'5%',
            height:2,
            backgroundColor:'#FE2B54',
            // alignSelf:'center',
            position:'relative',
            marginTop:20,
            zIndex:2,
          }}/>
  
          <p className='bold-text' 
          style={{
            width:'90%',
            marginLeft:'5%',
            marginTop:5,
            marginBottom:0,
            color:'#FE2B54',
            fontSize:'5.8vh',
            textAlign:'left',
            zIndex:2,
            }}>
          {title}
          </p>
  
  
          <p style={{
            width:'80%',
            marginLeft:'10%',
            textAlign:'left',
            color:'#FE2B54',
            fontWeight:'bold',
            fontSize:'2.5vh',
            marginBottom:10,
            zIndex:2,
          }}>
          {content1}
          </p>
  
          <p style={{
            width:'80%',
            marginLeft:'10%',
            textAlign:'left',
            color:'#FE2B54',
            fontWeight:400,
            fontSize:'2.5vh',
            marginTop:0,
            marginBottom:0,
            zIndex:2,
          }}>
          {content2}
          </p>
  
          
          {
            is_left ? (
              <img src={img_path} style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              left:'-20%',
              top:'-30%',
              width:'50%',
              zIndex:0,
            }}/>)
            :(
            <img src={img_path} style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              right:'-20%',
              bottom:'-30%',
              width:'50%',
              zIndex:1,
            }}/>
            )
          }
        
        
      </div>
    )
  }
  
  function PanelMobile({title, content1, content2, style, img_path, is_left}) {
    return (
      <div style={{
        // flex:1,
        minHeight:'32vh',
        // height:'60%',
        // backgroundColor: '#FF9F87',
        zIndex:2,
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        padding:0,
        paddingBottom:'2%',
        boxShadow: '0 10px 8px rgba(0, 0, 0, 0.1)',
        // marginTop:,
        ...style,
        }}>
          <div style={{
            backgroundColor: '#FF9F87',
            position:'absolute',
            width:'100%',
            height:'100%',
            zIndex:1,
          }}>
            
          </div>
  
          <div style={{
            width:'90%', 
            marginLeft:'5%',
            height:2,
            backgroundColor:'#FE2B54',
            // alignSelf:'center',
            position:'relative',
            marginTop:'5%',
            zIndex:2,
          }}/>
  
          {
            is_left ? (
              <img src={img_path} style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              left:'-20%',
              top:'-30%',
              height:'50%',
              zIndex:0,
            }}/>)
            :(
            <img src={img_path} style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              right:'-20%',
              bottom:'-30%',
              height:'50%',
              zIndex:1,
            }}/>
            )
          }
          
  
          <p className='bold-text' 
          style={{
            width:'90%',
            marginLeft:'5%',
            marginTop:5,
            marginBottom:0,
            color:'#FE2B54',
            fontSize:'2.5vh',
            textAlign:'left',
            zIndex:2,
            }}>
          {title}
          </p>
  
  
          <p style={{
            width:'90%',
            marginLeft:'5%',
            textAlign:'left',
            marginBottom:10,
            
            color:'#FE2B54',
            fontWeight:'bold',
            fontSize:'1.6vh',
            zIndex:2,
          }}>
          {content1}
          </p>
  
          <p style={{
            width:'90%',
            marginLeft:'5%',
            textAlign:'left',
            color:'#FE2B54',
            fontWeight:400,
            marginTop:0,
            marginBottom:0,
            fontSize:'1.5vh',
            zIndex:2,
          }}>
          {content2}
          </p>
        
      </div>
    )
  }
  
  function MoreInfoSectionMobile()
  {
  return (<div 
    style={{
      scrollSnapAlign: 'start',
      width:'100%',
      minHeight:'100vh',
  
      display:'flex',
      flexDirection:'column',
      justifyContent:'start',
      alignItems:'center',
      // marginTop: 100,
      // marginBottom: 100,
  
      position:'relative',
    }}>
          {/* <img src="/line1.svg" style={{
                    height:'75%',
                    position:'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex:0,
                    transform: 'translate(-50%, -50%)',
                    opacity:0.4
            }}/> */}
  
            <PanelMobile title="Fuel Your Freelance Potential" 
              content1="Unleash your creative talents and tap into the immense potential of social media marketing."
              content2="The future of marketing lies in the hands of young, passionate individuals who are just starting their journey as freelancers. In today's digital landscape, fresh perspective and innate understanding of social media make you a force to be reckoned with."
              img_path="/chuch2.svg"
              is_left={true}
  
              style={{
                // transform:'translate(30%, -55%)',
                // height:'32vh',
                width:'32vh',
                position:'relative',
                marginBottom:'2.5%',
                marginLeft:'20%',
                marginTop:'10vh'
                // marginRight:20,
                // justifyContent:'center'
              }}
            />
  
            
            <PanelMobile title="No Qualifications Needed" 
              content1="Gone are the days when traditional marketing strategies and extensive academic degrees held all the cards."
              content2="Today, what businesses truly need are young, innovative minds who grew up immersed in the world of social media. We believe that the key to effective marketing lies in the hands of content creators who possess an innate understanding of these platforms and the ever-evolving preferences of their audiences."
              img_path="/chuhca1.svg"
              is_left={false}
              style={{
                // transform:'translate(-30%, 55%)',
                // height:'32vh',
                width:'32vh',
                position:'relative',
                marginTop:'2.5%',
                marginRight:'20%'
                // marginLeft:20,
              }}
            />
  
            {/* <img src="/chuch2.svg" style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              left:'25%',
              top:'3%',
              width:'30%',
              zIndex:1,
            }}/>
  
            
            <img src="/chuhca1.svg" style={{
              position:'absolute',
              // left:-100,
              // top:-120,
              right:'15%',
              bottom:'10%',
              width:'30%',
              zIndex:2,
            }}/> */}
  
          </div>)
  }
  
  function MoreInfoSection()
  {
  return (<div 
    style={{
      scrollSnapAlign: 'start',
      minHeight:'100vh',
  
      display:'flex',
      flexDirection:'row',
      width:'100%',
      position:'relative',
      justifyContent:'center',
      alignItems:'center',
      // marginTop: 100,
      // marginBottom: 100,
    }}>
  
            <Panel title="Fuel Your Freelance Potential" 
              content1="Unleash your creative talents and tap into the immense potential of social media marketing."
              content2="The future of marketing lies in the hands of young, passionate individuals who are just starting their journey as freelancers. In today's digital landscape, fresh perspective and innate understanding of social media make you a force to be reckoned with."
              img_path="/chuch2.svg"
              is_left={true}
              style={{
                transform:'translate(0%, 25%)',
                marginRight:'5%',
                // justifyContent:'center'
              }}
            />
  
            
            <Panel title="No Qualifications Needed" 
              content1="Gone are the days when traditional marketing strategies and extensive academic degrees held all the cards."
              content2="Today, what businesses truly need are young, innovative minds who grew up immersed in the world of social media. We believe that the key to effective marketing lies in the hands of content creators who possess an innate understanding of these platforms and the ever-evolving preferences of their audiences."
              img_path="/chuhca1.svg"
              is_left={false}
              style={{
                transform:'translate(0%, -25%)',
                marginLeft:'5%',
              }}
            />
  
  
          </div>)
  }
  
  function AboutBuzzBizMobile({secondSectionRef})
  {
    return (
    <div 
    
    ref={secondSectionRef}
    style={{
      scrollSnapAlign: 'start',
  
      width:'100%',
      minHeight:'100vh',
      maxHeight:'100vh',
      overflowY:'hidden',
  
      display:'flex',
      flexDirection:'column',
      padding:0,
      position:'relative',
  
      backgroundColor: '#FE2B54', //FE2B54
    }}>
      <div style={{
        flex: 1,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'start',
        }}>
        
        <p className='bold-text' 
        style={{
          zIndex: 2, 
          margin:0,
          marginLeft:20,
          marginTop:'5%',
          color:'#FF9F87',
          fontSize:'5vh',
          }}>
        About
        </p>
        
        <p className='bold-text' 
        style={{
          zIndex: 2,
          margin:0, 
          marginLeft:20,
          color:'#FF9F87',
          fontSize:'5vh',
          }}>
        BuzzBiz
        </p>
        
        <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'500vmax',
            fontSize:'1.8vh'
          }}>
          Are you ready to unleash your creative genius and take control of your destiny? Join BuzzBiz, where the world becomes your canvas, and your imagination knows no bounds. Say goodbye to the 9-to-5 grind and embrace the freedom of freelancing. With BuzzBiz, you'll experience the thrill of a flexible schedule that fits your lifestyle, giving you the power to work when and where you want. Imagine the joy of waking up each day, excited to embark on projects that ignite your passion, as you collaborate with businesses that share your vision. Embrace the limitless possibilities, the potential for unlimited growth, and the buzz of earning money doing what you love.
          </p>
  
          
          <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'500vmax',
            fontSize:'1.8vh'
          }}>
          Join BuzzBiz today to break free from the ordinary, and let your creativity soar as you carve your path to success.
          </p>
  
          
          <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'bold',
            fontSize:'1.8vh'
          }}>
          Are you ready to create buzz?
          </p>
        
      </div>
      <div 
      style={{
        flex: 1,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        // width:'50%',
        }}>
  
        
      <img src="/girl.jpg" alt="logo" style={{
        width: '100%',
        // position:'absolute',
        // top: '30%',
        // left: '50%',
        // zIndex:1,
        // transform: 'translate(-50%, -50%)'
      }}/>
  
      </div>
    </div>);
  }
  
  function AboutBuzzBiz({secondSectionRef})
  {
    return (
  
      <div 
      
      ref={secondSectionRef}

      style={{
        scrollSnapAlign: 'start',
        width:'100%',
        minHeight:'100vh',
        maxHeight:'100vh',
        overflowY:'hidden',
    
        display:'flex',
        flexDirection:'row',
  
        position:'relative',
  
        'backgroundColor': '#FE2B54', 
      }}>
        <div style={{
          flex: 1,
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'start',
          }}>
          
          <p className='bold-text' 
          style={{
            zIndex: 2, 
            margin:0,
            marginLeft:20,
            color:'#FF9F87',
            fontSize:'5vmax',
            }}>
          About
          </p>
          
          <p className='bold-text' 
          style={{
            zIndex: 2,
            margin:0, 
            marginLeft:20,
            color:'#FF9F87',
            fontSize:'5vmax',
            }}>
          BuzzBiz
          </p>
          
          <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'500vmax',
            fontSize:'3vh'
          }}>
          Are you ready to unleash your creative genius and take control of your destiny? Join BuzzBiz, where the world becomes your canvas, and your imagination knows no bounds. Say goodbye to the 9-to-5 grind and embrace the freedom of freelancing. With BuzzBiz, you'll experience the thrill of a flexible schedule that fits your lifestyle, giving you the power to work when and where you want. Imagine the joy of waking up each day, excited to embark on projects that ignite your passion, as you collaborate with businesses that share your vision. Embrace the limitless possibilities, the potential for unlimited growth, and the buzz of earning money doing what you love.
          </p>
  
          
          <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'500vmax',
            fontSize:'3vh'
          }}>
          Join BuzzBiz today to break free from the ordinary, and let your creativity soar as you carve your path to success.
          </p>
  
          
          <p style={{
            textAlign:'left',
            paddingLeft:20,
            paddingRight: 20,
            color:'white',
            fontWeight:'bold',
            fontSize:'3vh'
          }}>
          Are you ready to create buzz?
          </p>
          
        </div>
        <div 
        style={{
          'backgroundColor': '#FE2B54', flex: 1,
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          // width:'50%',
          }}>
  
          
        <img src="/girl.jpg" alt="logo" style={{
          width: '100%',
          // position:'absolute',
          // top: '30%',
          // left: '50%',
          // zIndex:1,
          // transform: 'translate(-50%, -50%)'
        }}/>
  
        </div>
      </div>
    );
  }
  
  function Button({text, style, text_style})
  {
    return (
      <button style={{
          backgroundColor: '#C5556E', // Solid fill color
          border: 'none', // No border
          color: 'white', // Text color
          padding: '15px 32px', // Space around the text
          textAlign: 'center', // Text alignment
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px', // Text size
          borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
          cursor: 'pointer', // Change mouse cursor on hover
          transitionDuration: '0.4s', // Transition effect on hover
          marginTop: 20,
          width: 250,
          height: 50,
          zIndex:2,
          ...style,
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#aaaaaa'} // Darker color on hover
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#C5556E'} // Original color when mouse is not hovering
      >
        <p className='bold-text' 
        style={{
          zIndex: 2, 
          margin:0,
          fontSize:20,
          ...text_style,
          }}>
        {text}
        </p>
  
      </button>);
  }
  
  function HeaderSection({isMobile, handleButtonClick}){
    return (
    <div style={{
      scrollSnapAlign: 'start',
      position:'relative',
  
      width:'100%',
      // maxWidth:1000,
      minHeight:'100vh',
      backgroundColor:'#FE2B54',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
    }}>
          <img src="/logo.PNG" alt="logo" style={{
            height:'10%',
            marginTop: '5vh',
            marginBottom: '10%',
            zIndex:2,
            position:'absolute',
            top:0,
          }}/>
  
  
          <img src="/line1.svg" style={{
            height:'75%',
            position:'absolute',
            top: '50%',
            left: '50%',
            zIndex:0,
            transform: 'translate(-50%, -50%)'
          }}/>
          
          <div style={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            marginTop:'15%',
          }}>
          <p className='bold-text' style={{zIndex: 2, margin: 0, fontSize: '6vmax'}}>Empowering Businesses.</p>
          <p className='bold-text' style={{zIndex: 2, margin: 0, fontSize: '6vmax'}}>Connecting Creators.</p>
          </div>
  
          <button style={{
              backgroundColor: '#C5556E', // Solid fill color
              borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
              border: 'none', // No border
              color: 'white', // Text color
  
              textAlign: 'center', // Text alignment
              textDecoration: 'none',
              
              cursor: 'pointer', // Change mouse cursor on hover
              transitionDuration: '0.4s', // Transition effect on hover
              marginTop: '5vh',
              width: ( (isMobile ? '30vh' : '50vh') ),
              height: (isMobile ? '6vh' : '10vh'),
              zIndex:2,
  
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#aaaaaa'} // Darker color on hover
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#C5556E'} // Original color when mouse is not hovering
              onClick={handleButtonClick}
          >
            <p className='bold-text' 
            style={{
              zIndex: 2, 
              margin:0,
              fontSize:(isMobile ? '2.5vh' : '5vh'),
              }}>
            Learn More
            </p>
  
          </button>
  
          </div>
          
    );
  }
  
  function CreateWithUs({navigate})
  {
    return (
  
      <div style={{
        scrollSnapAlign: 'start',
        width:'100%',
        // maxWidth:1000,
        minHeight:'100vh',
        marginTop:150,
    
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        
        position:'relative',
      }}>
        <div style={{
          // flex:1,
          width:'50%',
          height:'100%',
          'backgroundColor': '#FE2B54',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'start',
          }}>
          
          <img src="/logo.PNG" alt="logo" style={{
            height:'10vh',
            marginLeft:'5%',
          }}/>
  
          <p className='bold-text' 
          style={{
            zIndex: 2, 
            margin:0,
            marginLeft:'5%',
            marginTop:'5%',
            color:'white',
            fontSize:'3vmax',
            }}>
          Create with us
          </p>
          
          <p style={{
            textAlign:'left',
            paddingLeft:'5%',
            paddingRight: '5%',
            color:'white',
            fontWeight:20,
            fontSize:'2vmax',
          }}>
          Join us in empowering  creators to shape the future of marketing and pave their way to success. Let your youthful energy and digital fluency propel you to new heights as you embark on an exhilarating journey of freelance discovery with BuzzBiz.
          </p>
          
  
          <button style={{
              backgroundColor: '#C5556E', // Solid fill color
              borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
              border: 'none', // No border
              color: 'white', // Text color
  
              textAlign: 'center', // Text alignment
              textDecoration: 'none',
              
              marginTop: '1vh',
              marginLeft: '5%',
  
              cursor: 'pointer', // Change mouse cursor on hover
              transitionDuration: '0.4s', // Transition effect on hover
              
              width: '40vmax',
              height: '5vmax',
              zIndex:2,
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#aaaaaa'} // Darker color on hover
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#C5556E'} // Original color when mouse is not hovering
              onClick={(e)=> {navigate('/colab'); }}
          >
            <p className='bold-text' 
            style={{
              zIndex: 2, 
              margin:0,
              fontSize:'2vmax',
              }}>
            JOIN THE BUZZBIZ CREATOR TEAM
            </p>
  
          </button>
  
  
        </div>
        <div 
        style={{
          'backgroundColor': '#FE2B54',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          width:'50%',
          height:'100%',
          }}>
  
          
        <div style={{
          // display:'flex',
          overflow:'hidden',
          // maxWidth:'50%',
        }}>
        <img src="/chucha3.svg" alt="logo" style={{
          width: '150%',
          position:'relative',
          // top: '30%',
          left: '10%',
          // zIndex:1,
          // transform: 'translate(-50%, -50%)'
        }}/>
        </div>
  
        </div>
      </div>
    );
  }


function LandingPage()
{
    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const navigate = useNavigate();

    const secondSectionRef = useRef(null);
    
    
    // Function to handle the button click
    const handleButtonClick = () => {
        // Scroll to the second section
        secondSectionRef.current.scrollIntoView({
        behavior: 'smooth', // for smooth scrolling
        block: 'start', // Scroll the start of the section into view
        });
    };

    return (

        <div style={{
        width:'100%',
        // maxWidth: 1000,
        
        
        display:'flex',
        flexDirection:'column',
        alignItems:'center',

        textAlign:'center',
        margin: "auto",
        // margin:0,
        padding:0,
        backgroundColor:'blue',
        backgroundColor:'#FE2B54',

        scrollSnapType: 'y mandatory',
        overflowY: 'scroll',
        height: '100vh',

        overflowX:'hidden',
        }}>
            
            <HeaderSection isMobile={isMobile} handleButtonClick={handleButtonClick}/>

            {/* section divider */}
            {isMobile ? (
            <AboutBuzzBizMobile secondSectionRef={secondSectionRef}/>
            ) : (
            <AboutBuzzBiz secondSectionRef={secondSectionRef}/>
            )}
            
            {/* section divider */}
            {isMobile ? (
            <MoreInfoSectionMobile/>
            ) : (
            <MoreInfoSection/>
            )}
            
            
            {/* section divider */}
            <CreateWithUs navigate={navigate}/>
        
        </div>
    );
}

export default LandingPage;