import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate  } from 'react-router-dom';



function FormPage()
{
    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const navigate = useNavigate();
    


    return (
        <div style={{
            width:'100%',
            // maxWidth: 1000,
            
            
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
    
            textAlign:'center',
            margin: "auto",
            // margin:0,
            padding:0,
            backgroundColor:'blue',
            backgroundColor:'#FE2B54',
    
            scrollSnapType: 'y mandatory',
            overflowY: 'scroll',
            height: '100vh',
    
            overflowX:'hidden',
            }}>
            
            
            <div style={{
                scrollSnapAlign: 'start',
        
                width:'100%',
                minHeight:'100vh',
                maxHeight:'100vh',
                overflowY:'hidden',
            
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
        
                position:'relative',
        
                'backgroundColor': '#FE2B54', 
            }}>

            <img src="/line1.svg" style={{
                height:'75%',
                position:'absolute',
                top: '50%',
                left: '50%',
                zIndex:0,
                transform: 'translate(-50%, -50%)',
                opacity:0.5
            }}/>

            <img src="/logo.PNG" alt="logo" style={{
            height:'10%',
            marginTop: '5vh',
            marginBottom: '10%',
            zIndex:2,
            position:'absolute',
            top:0,
            }}/>

            
            <p className='bold-text' style={{zIndex: 2, margin: 0, fontSize: '5vmax', marginBottom:'1%'}}>Welcome to BuzzBiz!</p>
            
            <p className='bold-text' style={{zIndex: 2, margin: 0, fontSize: '2vmax'}}>Are you ready to kickstart your career as a freelance content creator?</p>
            
            
          <button style={{
              backgroundColor: '#FF9F87', // Solid fill color
              borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
              border: 'none', // No border
              color: 'white', // Text color
  
              textAlign: 'center', // Text alignment
              textDecoration: 'none',
              
              cursor: 'pointer', // Change mouse cursor on hover
              transitionDuration: '0.4s', // Transition effect on hover
              marginTop: '5vh',
              width: ( (isMobile ? '80vw' : '100vh') ),
              height: (isMobile ? '6vh' : '10vh'),
              zIndex:2,
  
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#aaaaaa'} // Darker color on hover
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF9F87'} // Original color when mouse is not hovering
              onClick={(e) => {navigate('/form')}}
          >
            <p className='bold-text' 
            style={{
              zIndex: 2, 
              margin:0,
              fontSize:(isMobile ? '2.5vh' : '4vh'),
              }}>
            YES, LET'S DO IT!
            </p>
  
          </button>
          

          <button style={{
              backgroundColor: '#FF9F87', // Solid fill color
              borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
              border: 'none', // No border
              color: 'white', // Text color
  
              textAlign: 'center', // Text alignment
              textDecoration: 'none',
              
              cursor: 'pointer', // Change mouse cursor on hover
              transitionDuration: '0.4s', // Transition effect on hover
              marginTop: '5vh',
              width: ( (isMobile ? '80vw' : '100vh') ),
              height: (isMobile ? '8vh' : '10vh'),
              zIndex:2,
  
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#aaaaaa'} // Darker color on hover
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF9F87'} // Original color when mouse is not hovering
              onClick={(e) => {
                window.location.href = 'https://www.firstsession.com/resources/how-to-find-a-therapist'
              }}
          >
            <p className='bold-text' 
            style={{
              zIndex: 2, 
              margin:0,
              fontSize:(isMobile ? '2.5vh' : '4vh'),
              }}>
            NO, I'D RATHER STICK TO MY SOUL WRENCHING CORPORATE JOB.
            </p>
  
          </button>


            </div>
        </div>
    );
}

export default FormPage;
