import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate  } from 'react-router-dom';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp  } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCaLnIs3TJWg6Z-nubiYeSdSqTtVvJucIQ",
    authDomain: "buzzbiz-20db8.firebaseapp.com",
    projectId: "buzzbiz-20db8",
    storageBucket: "buzzbiz-20db8.appspot.com",
    messagingSenderId: "464460119456",
    appId: "1:464460119456:web:f6dc576aaed036b08f78c5",
    measurementId: "G-MTJCZCG5FV"
    };
    
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  // Initialize Firestore
  const db = getFirestore(app);
      
  
function TextBox({defaultValue, state, setState, isMobile})
{
  return (
    
    <textarea style={{
      width:(isMobile ? '80vw' : '30vmax'),
      height:(isMobile ? '5vh' : '3vmax'),
      marginTop:(isMobile ? '2%' : '1%'),

      backgroundColor: 'white',
      border: 'none',
      resize: 'none',
      
      fontSize:(isMobile ? '5vw' : '2vmax'),
      fontFamily:'Telegraf Medium',
      color:'#000000',

      borderRadius: '0%',
      backgroundColor:'rgba(255,255,255,0.3)',
      transition:'0.3s all',

      position:'relative',
      overflowY:'hidden',
      overflowX: 'visible',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',

      zIndex:3,
    }} 

    onMouseEnter={(e)=> {
      e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
      e.currentTarget.style.boxShadow = '0px 4px 20px 0px rgba(0, 0, 0, 0.05)';
    }}

    
    onMouseLeave={(e)=> {
      e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.3)';
      e.currentTarget.style.boxShadow = '0px 4px 20px 0px rgba(0, 0, 0, 0.00)';
    }}

    placeholder={defaultValue}
    value={state} 
    onChange={(e) => {
      setState(e.target.value);
      }} 

    />

  );
}



function InputBox({defaultValue, state, setState, isMobile, style})
{

  const [isFocused, setIsFocused] = useState(false);

  return (
    <input style={{
      width:(isMobile ? (isFocused ? '85vw' : '80vw') : (isFocused ? '32vmax' : '30vmax')),
      height:(isMobile ? '7vw' : '3vmax'),
      marginTop:(isMobile ? '2%' : '1%'),

      fontSize:(isMobile ? '5vw' : '2vmax'),
      fontFamily:'Telegraf Medium',
      color:'#000000',

      backgroundColor:(isFocused ? 'rgba(255,255,255,0.8)': 'rgba(255,255,255,0.3)'),
      border: 'none',
      borderBottom:'none',
      outline:'none',
      resize: 'none',
      
      position:'relative',
      overflowY:'hidden',
      overflowX: 'visible',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',

      zIndex:3,
      transition:'0.3s all',
      ...style
    }}

    onBlur={(e)=> {
      if(e.target.value.length === 0)
        setIsFocused(false);
    }}

    onFocus={(e)=> {
      setIsFocused(true);
      // e.currentTarget.style.width = isMobile ? '85vw' : '32vmax';
      // e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
      // e.currentTarget.style.boxShadow = '0px 4px 20px 0px rgba(0, 0, 0, 0.05)';
    }}
    

    onMouseEnter={(e)=> {
      if(!isFocused)
      {
          e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.4)';
          e.currentTarget.style.boxShadow = '0px 4px 20px 0px rgba(0, 0, 0, 0.05)';
      }
    }}

    
    onMouseLeave={(e)=> {
      if(!isFocused)
      {
        e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.3)';
        e.currentTarget.style.boxShadow = '0px 4px 20px 0px rgba(0, 0, 0, 0.00)';
      }
    }}

    
    placeholder={defaultValue}
    value={state} 
    onChange={(e) => {
      setState(e.target.value);
      if(e.target.value.length > 0)
      {
        setIsFocused(true);
      } 
      else
      {
        setIsFocused(false);
      }
      }} 
    >

    </input>

  );
}

function ShortQuestions({ 
  isMobile, firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone, tiktokUser, setTikTokUser, igUser, setIgUser
 })
{
  return (
      <>
        { (!isMobile) ? 

        (<div style={{
          display:'flex', flexDirection:'column',
          justifyContent:'center', alignItems:'center',
        }}>

        <div style={{
          display:'flex', flexDirection:'row',
          justifyContent:'center', alignItems:'center',
        }}>
          <InputBox isMobile={isMobile}
          defaultValue={"First Name*"} state={firstName} setState={setFirstName}
          style={{marginRight:'5%'}}
          />
          
          <InputBox isMobile={isMobile}
          defaultValue={"Last Name*"} state={lastName} setState={setLastName}
          />
        </div>


        <div style={{
          display:'flex', flexDirection:'row',
          justifyContent:'center', alignItems:'center',
        }}>

          <InputBox isMobile={isMobile}
          defaultValue={"Email*"} state={email} setState={setEmail}
          style={{marginRight:'5%'}}
          />
          <InputBox isMobile={isMobile}
          defaultValue={"Phone Number*"} state={phone} setState={setPhone}
          />

        </div>


        <div style={{
          display:'flex', flexDirection:'row',
          justifyContent:'center', alignItems:'center',
        }}>

          <InputBox isMobile={isMobile}
          defaultValue={"TikTok Username (optional)"} state={tiktokUser} setState={setTikTokUser}
          style={{marginRight:'5%'}}
          />
          <InputBox isMobile={isMobile}
          defaultValue={"Instagram Username (optional)"} state={igUser} setState={setIgUser}
          />

        </div>

        </div>)
        :
        (
        <>
        <InputBox isMobile={isMobile}
        defaultValue={"First Name"} state={firstName} setState={setFirstName}
        />
        <InputBox isMobile={isMobile}
        defaultValue={"Last Name"} state={lastName} setState={setLastName}
        />
        <InputBox isMobile={isMobile}
        defaultValue={"Email"} state={email} setState={setEmail}
        />
        <InputBox isMobile={isMobile}
        defaultValue={"Phone Number"} state={phone} setState={setPhone}
        />
        
        <InputBox isMobile={isMobile}
        defaultValue={"TikTok Username (optional)"} state={tiktokUser} setState={setTikTokUser}
        />
        <InputBox isMobile={isMobile}
        defaultValue={"Instagram Username (optional)"} state={igUser} setState={setIgUser}
        />
        </>
        )
        
        }
        
      </>
  );
}

function FormQuizPage()
{

    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const [canSubmit, setCanSubmit] = useState(false);
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tiktokUser, setTikTokUser] = useState('');
    const [igUser, setIgUser] = useState('');


    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const docRef = await addDoc(collection(db, "user_interests"), {
              firstName: firstName,
              lastName: lastName,
              email: email,
              phone: phone,
              'tiktokUser': tiktokUser,
              'igUser': igUser,
              timestamp: serverTimestamp()
            });
            console.log("Document written with ID: ", docRef.id);

            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setEmail("");
            setTikTokUser("");
            setIgUser("");
            navigate('/thankyou');

        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    useEffect(() => {
      if(firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0)
      {
        setCanSubmit(true);
      }
      else
      {
        setCanSubmit(false);
      }
    }, [firstName, lastName, email, phone])

    return (
        <div style={{
            width:'100%',
            // maxWidth: 1000,
            
            
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
    
            textAlign:'center',
            margin: "auto",
            // margin:0,
            padding:0,
            backgroundColor:'blue',
            backgroundColor:'#FE2B54',
    
            minHeight: '100vh',
    
            overflowX:'hidden',
            }}>
            
            
            <div style={{
        
                width:'100%',
                minHeight:'100vh',
                // maxHeight:'100vh',
            
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'center',
        
                position:'relative',
        
                'backgroundColor': '#FE2B54', 
            }}>


            <div style={{
              width:'100%',
              height:'20vh',
              // backgroundColor:'blue',
            }}></div>

            <img src="/line1.svg" style={{
                height:'75%',
                position:'fixed',
                top: '50%',
                left: '50%',
                zIndex:0,
                transform: 'translate(-50%, -50%)',
                opacity:0.5
            }}/>

            <img src="/logo.PNG" alt="logo" style={{
            height:'10%',
            marginTop: '5vh',
            marginBottom: '10%',
            zIndex:2,
            position:'absolute',
            top:0,
            }}/>

            
            <p className='bold-text' style={{zIndex: 2, margin: 0, fontSize: '5vmax', marginBottom:'1%', marginTop:0,}}>We'll Contact You!</p>
            
            <p className='bold-text' 
            style={{
              zIndex: 2, margin: 0, fontSize: '2vmax', maxWidth:'80%', 
              marginBottom:'2vh'}}>Please leave us your contact information and one of our Talent Acquisition Specialists will be in touch shortly.</p>
            
            <ShortQuestions 
              isMobile={isMobile}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              tiktokUser={tiktokUser}
              setTikTokUser={setTikTokUser}
              igUser={igUser}
              setIgUser={setIgUser}
            />

            <button style={{
                opacity: (canSubmit ? '1.0' : '0.5') ,
                backgroundColor: '#FF9F87', // Solid fill color
                borderRadius: '4px', // Rounded corners (set this to '0' for no roundness)
                border: 'none', // No border
                color: 'white', // Text color
    
                textAlign: 'center', // Text alignment
                textDecoration: 'none',
                
                cursor: 'pointer', // Change mouse cursor on hover
                transitionDuration: '0.4s', // Transition effect on hover
                marginTop: '5vh',
                marginBottom:'10vh',
                width: ( (isMobile ? '80vw' : '15vw') ),
                height: (isMobile ? '8vw' : '4vw'),
                zIndex:2,
    
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                }}
                onMouseOver={(e) => {
                      if(canSubmit)
                        e.currentTarget.style.backgroundColor = '#aaaaaa';
                    } 
                  } // Darker color on hover
                onMouseOut={(e) => {
                    if(canSubmit)
                      e.currentTarget.style.backgroundColor = '#FF9F87';
                  }} // Original color when mouse is not hovering
                onClick={(e) => {
                  if(canSubmit)
                    handleSubmit();
                  }}
            >
              <p className='bold-text' 
              style={{
                zIndex: 2, 
                margin:0,
                fontSize:(isMobile ? '2.5vw' : '4vh'),
                }}>
              SUBMIT
              </p>
    
            </button>


            </div>
        </div>
    );
}

export default FormQuizPage;
