
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter as Router,
  Route,
  Routes 
} from 'react-router-dom';


import './App.css';

import LandingPage from './pages/LandingPage';
import FormPage from './pages/Form';
import FormQuizPage from './pages/FormQuiz';
import ThankYou from './pages/ThankYou';


function App() {

  return (

        <Router>
            <Routes>
                <Route path="/" exact element={<LandingPage/>} />
                <Route path="/colab" element={<FormPage/>} />
                <Route path="/form" element={<FormQuizPage/>} />
                <Route path="/thankyou" element={<ThankYou/>} />
            </Routes>
        </Router>

  );
}


export default App;
